<script>
  import { createEventDispatcher } from 'svelte';
  import Spinner from './Spinner.svelte';
  export let loader = false;
  export let disabled = false;
  const dispatch = createEventDispatcher();
</script>

<button
  on:click|preventDefault={() => dispatch('buttonClick')}
  class={$$props.class}
  {disabled}
  data-cy={$$props.dataCy}
  id={$$props.id}
>
  {#if loader}
    <Spinner />
  {/if}
  <slot />
</button>

<style>
  button {
    background: var(--primary-color);
    color: var(--white-color);
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    width: 100%;
    justify-self: flex-end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button.feedback-initial {
    background: cornflowerblue;
  }
  button.feedback-success {
    background: #13a913;
    grid-column: 1/3;
    padding: 1rem;
  }
  button.feedback-error {
    background: var(--primary-color);
    grid-column: 1/3;
    padding: 1rem;
  }
  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  button :global(.spinner) {
    position: absolute;
    right: 16px;
    top: 14px;
  }
  @media (max-width: 480px) {
    button {
      width: 100%;
    }
  }
</style>
