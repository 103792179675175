<script>
  import { store, results } from '../../../stores/store.js';

  // Components
  import Input from '../../fields/Input.svelte';
  import Textarea from '../../fields/Textarea.svelte';
  import Logs from '../../Logs.svelte';
</script>

<Input
  text="Revogar o consentimento API"
  bind:value={$store.business.payment.revokeAPI}
  key="revokeAPIField"
/>
<Textarea
  text="Revogar o consentimento (JSON)"
  key="revokeConsentPayload"
  bind:value={$store.business.payment.revokePayload}
/>
<slot name="buttons" />
{#if $results?.payment?.revokePayment}
  <div class="result">
    <h3>Resultado da revogação do consentimento:</h3>
    <Logs logs={$results?.payment?.revokePayment} />
  </div>
{/if}
