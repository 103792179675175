export const PermissionEnum = {
  CUSTOMERS: "customers",
  CAPITALIZATION_TITLE: "capitalization-title",
  PENSION_PLAN: "insurance-pension-plan",
  LIFE_PENSION: "insurance-life-pension",
  DAMAGES_AND_PEOPLE_PATRIMONIAL: "insurance-patrimonial",
  DAMAGES_AND_PEOPLE_RESPONSIBILITY: "insurance-responsibility",
  DAMAGES_AND_PEOPLE_TRANSPORT: "insurance-transport",
  DAMAGES_AND_PEOPLE_FINANCIAL_RISKS: "insurance-financial-risk",
  DAMAGES_AND_PEOPLE_RURAL: "insurance-rural",
  DAMAGES_AND_PEOPLE_AUTO: "insurance-auto",
  DAMAGES_AND_PEOPLE_HOUSING: "insurance-housing",
  DAMAGES_AND_PEOPLE_ACCEPTANCE_AND_BRANCHES_ABROAD: "insurance-acceptance-and-branches-abroad",
  DAMAGES_AND_PEOPLE_PERSON: "insurance-person",
  CLAIM_NOTIFICATION: "claim-notification",
  ENDORSEMENT: "endorsement",
  FINANCIAL_ASSISTANCE_READ: "insurance-financial-assistance",
  RESOURCES: "resources"
};