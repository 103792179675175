<script>
  import { flip } from "svelte/animate";
  import { fly } from "svelte/transition";
  import { notifications } from "../stores/notifications.js";

  export let themes = {
      danger: {
        bg: 'indianred',
        color: 'white',
        icon: 'error'
      },
      success: {
        bg: 'lightgreen',
        color: 'darkgreen',
        icon: 'check'
      },
      warning: {
        bg: 'lightyellow',
        color: 'orangered',
        icon: 'warning'
      },
      info: {
        bg: 'lightblue',
        color: 'darkblue',
        icon: 'info'
      },
      default: {
        bg: 'var(--primary-color)',
        color: 'white'
      },
  };
</script>

<div class="notifications">
  {#each $notifications as notification (notification.id)}
      <div
          animate:flip
          class="toast"
          style="background: {themes[notification.type].bg};"
          transition:fly={{ y: 30 }}
      >
        
        <div class="content" style="color: {themes[notification.type].color};">
          <span class="material-icons icon">
            {themes[notification.type].icon}
          </span>
          {notification.message}
        </div>
      </div>
  {/each}
</div>

<style>
  .notifications {
      position: fixed;
      top: 10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      padding: 0;
      z-index: 9999;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      pointer-events: none;
  }

  .toast {
      flex: 0 0 auto;
      margin-bottom: 10px;
      border-radius: .5rem;
  }

  .content {
      padding: 1rem 2rem;
      display: flex;
      align-items: center;
      color: white;
      font-weight: 500;
  }

  .content .icon {
    margin-right: .5rem;
  }
  
</style>
