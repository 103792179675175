export const serviceUrlsV1 = [
    {
      type: 'claim-notification',
      name: 'claim-notification',
      basePath: '/open-insurance/claim-notification',
      urls: [
        {
          method: 'POST',
          path: '/v1/request/damage/{ID}',
          validateToken: true,
          scope: 'claim-notification',
          validateJWS: false,
          consentPermission: 'CLAIM_NOTIFICATION_REQUEST_DAMAGE_CREATE',
          validateConsentId: true,
          mtls: true,
        },
        {
          method: 'POST',
          path: '/v1/request/person/{ID}',
          validateToken: true,
          scope: 'claim-notification',
          validateJWS: false,
          consentPermission: 'CLAIM_NOTIFICATION_REQUEST_PERSON_CREATE',
          validateConsentId: true,
          mtls: true,
        }
      ]
    },
    {
      type: 'endorsement',
      name: 'endorsement',
      basePath: '/open-insurance/endorsement',
      urls: [
        {
          method: 'POST',
          path: '/v1/request/{ID}',
          validateToken: true,
          scope: 'endorsement',
          validateJWS: false,
          consentPermission: 'ENDORSEMENT_REQUEST_CREATE',
          validateConsentId: true,
          mtls: true,
        }
      ]
    }
]