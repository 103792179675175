<script>
  import { sections } from '../stores/store.js';
  import * as formComponents from '../components/forms/index.js';
  import Section from '../components/Section.svelte';

</script>

<div class="sections">
  {#each $sections as section (section.id)}
    <Section title={section.title} isOpen={section.visible} id={section.id}>
      <svelte:component this={formComponents[section.component]} />
    </Section>
  {/each}
</div>