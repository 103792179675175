<script>
  import {
    results,
    updatePaymentResult,
    getResultPayment,
    getResultDataSharing,
    updateDataSharingResult,
    getResultService,
    updateServiceResult,
    getStore,
  } from '../stores/store.js';
  import { onMount } from 'svelte';
  import { useNavigate } from 'svelte-navigator';
  import { ConsentType } from '../utils/consent-type.js';

  import Spinner from '../components/Spinner.svelte';
  import Logs from '../components/Logs.svelte';

  import Api from '../services/Api.js';

  function updateResult(data) {
    const key = 'authentication';
    if (getStore('business').consentType === ConsentType.DATA_SHARING) {
      const authData = getResultDataSharing(key);
      updateDataSharingResult(key, { ...authData, result: { ...data } });
    }

    if (getStore('business').consentType === ConsentType.PAYMENT) {
      const authData = getResultPayment(key);
      updatePaymentResult(key, { ...authData, result: { ...data } });
    }

    if (getStore('business').consentType === ConsentType.SERVICE) {
      const authData = getResultService(key);
      updateServiceResult(key, { ...authData, result: { ...data } });
    }
  }

  onMount(async () => {
    const payload = window.location.hash
      .slice(1)
      .split('&')
      .reduce(function _reduce(a, b) {
        b = b.split('=');
        a[b[0]] = decodeURIComponent(b[1]);
        return a;
      }, {});

    const cbResponse = await Api.post('/cb', payload).catch(
      ({ response }) => response.data
    );
    updateResult(cbResponse);
    window.open('/', '_self');
  });
</script>

<div class="cb-page">
  <h2 class="text">Processando callback</h2>
  <Spinner color="var(--border-field-color)" size="80" />
  <Logs logs={$results?.authentication} />
</div>

<style>
  .cb-page {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 1rem;
    color: var(--border-field-color);
  }
  .cb-page .text {
    margin-bottom: 2rem;
  }
  .cb-page :global(.logs) {
    display: none;
  }
</style>
