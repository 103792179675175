<script>
  import { onDestroy, onMount } from 'svelte';
  import {
    results,
    getStore,
    getResult,
    getActiveStepIndex,
    updateServiceResult,
  } from '../../stores/store.js';
  import { notifications } from '../../stores/notifications.js';

  import * as stepFormComponents from './steps/index.js';

  import Button from '../Button.svelte';
  import Stepper from '../Stepper.svelte';
  import Section from '../Section.svelte';
  import ConsultServiceForm from './ConsultServiceForm.svelte';

  import Api from '../../services/Api.js';

  import {
    configDataIsOk,
    getConfigData,
  } from '../../utils/handleConfigData.js';
  import { ConsentType } from '../../utils/consent-type.js';
  import { PermissionEnum } from '../../utils/permission.js';

  let socket;

  let steps = [
    {
      id: 1,
      key: 'consentIntent',
      name: 'Consentimento',
      active: true,
      component: 'ConsentIntentStepForm',
      api: 'consent/service',
      button: {
        text: 'Iniciar intenção de consentimento',
        loader: false,
        status: null,
        icon: null,
      },
    },
    {
      id: 2,
      key: 'authentication',
      name: 'Autenticação',
      active: false,
      component: 'AuthenticationStepForm',
      api: 'auth',
      button: {
        text: 'Obter URL de autenticação',
        loader: false,
        status: null,
        icon: null,
      },
    },
    {
      id: 3,
      key: 'service',
      name: 'Endosso e Sinistro',
      active: false,
      component: 'ConsultServiceStepForm',
      api: 'service',
      method: 'post',
      button: {
        text: 'Consultar endpoint',
        loader: false,
        status: null,
        icon: null,
      },
    },
    {
      id: 4,
      key: 'rejectService',
      name: 'Rejeitar',
      active: false,
      component: 'RejectConsentStepForm',
      api: 'consent/reject',
      method: 'post',
      button: {
        text: 'Rejeitar Consentimento',
        loader: false,
        status: null,
        icon: null,
      },
    },
  ];

  let stepWidth = 0;

  /**
   * Verifica qual é a etapa ativa
   * @returns void
   */
  onMount(() => {
    const activeStepIndex = getActiveStepIndex(ConsentType.SERVICE);
    steps = steps.map((step, index) => {
      return {
        ...step,
        active: index === activeStepIndex,
      };
    });
  });

  /**
   * Desconecta a conexão com o socket
   * @returns void
   */
  onDestroy(() => socket && socket.disconnect());

  /**
   * Método para atualizar o resultado de acordo com a etapa ativa
   * @params key, data => chave e valor do resultado
   * @returns void
   */
  const updateResult = (key, data) => {
    updateServiceResult(
      key,
      key === 'authentication'
        ? {
            ...(data?.authUrl ? { authUrl: data.authUrl } : { result: data }),
          }
        : data
    );
  };

  /**
   * Método para resetar o resultado de acordo com a etapa ativa
   * @params stepIndex => indice da etapa para comparação
   * @returns void
   */
  const resetStepResult = (stepIndex) =>
    results.update((results) => {
      const newServiceObject = {};
      Object.keys(results.service).forEach((key, index) => {
        return index >= stepIndex
          ? (newServiceObject[key] = null)
          : (newServiceObject[key] = results.service[key]);
      }, {});

      return {
        service: newServiceObject,
        dataSharing: results.dataSharing,
      };
    });

  /**
   * Método para obter o status do botão
   * @params text, status @type 'ERROR' | 'SUCCESS' => texto do botão
   * @returns @type { text, icon, status }
   */
  const getStatusButton = (text, status = 'ERROR') => {
    if (status === 'ERROR') {
      return {
        text: `ERRO: ${text}`,
        icon: 'error',
        status: 'error',
        loader: false,
      };
    } else {
      return {
        text: `SUCESSO: ${text}`,
        icon: 'check',
        status: 'success',
        loader: false,
      };
    }
  };

  /**
   * Método para atualizar o status do botão da etapa ativa
   * @params index, newButtonStatus
   *  => índice da etapa para atualização do status do botão de ação e o novo status do botão
   * @returns void
   */
  const updateStepActionButtonStatus = (index, newButtonStatus) => {
    const currentButtonStatus = steps[index].button;
    steps = steps.map((step, i) => {
      return {
        ...step,
        ...(i === index && {
          button: { ...currentButtonStatus, ...newButtonStatus },
        }),
      };
    });
  };

  /**
   * Método para lidar com sucesso na requisição, atualiza status do botão de ação e exibe uma notificação
   * @params name, index
   *  => Nome da etapa para mensagem de feeback (botão e notificação) e índice da etapa para atualização do status do botão de ação
   * @returns void
   */
  const handleSuccess = (name, index) => {
    updateStepActionButtonStatus(index, {
      ...getStatusButton(name, 'SUCCESS'),
    });
    notifications.success(`SUCESSO: ${name}`, 2000);
  };

  /**
   * Método para lidar com erro na requisição, atualiza status do botão de ação e exibe uma notificação
   * @params name, index
   *  => Nome da etapa para mensagem de feeback (botão e notificação) e índice da etapa para atualização do status do botão de ação
   * @returns void
   */
  const handleError = (name, index) => {
    updateStepActionButtonStatus(index, { ...getStatusButton(name) });
    notifications.danger(`ERRO: ${name}`, 2000);
  };

  /**
   * Método para obter o payload da requisição
   * @params key => chave para definir qual(is) campo(s) terá(ão) no payload
   * @returns any
   */
  const getPayload = (key) => {
    switch (key) {
      case 'consentIntent':
        const { consentPayload, consentsAPI } =
          getStore('business').service;
        return {
          ...getConfigData(),
          consentPayload,
          consentsAPI: consentsAPI?.trim(),
        };
      case 'authentication':
        const { data } = getResult('service')?.consentIntent;
        const scopes = getScopes(data);
        const filteredScopes = `openid consents consent:${data?.consentId} ${Array.from(scopes).join(' ')}`;
        return {
          scope: filteredScopes,
        };
      case 'service':
        const { servicePayload, serviceAPI } = getStore('business').service;
        return {
          ...getConfigData(),
          servicePayload,
          serviceAPI: serviceAPI?.trim(),
          tokens: getResult('service').authentication?.result?.tokens,
        };
      case 'rejectService':
        const { rejectAPI } = getStore('business').service;
        const authenticationResult =
          getResult('service').authentication?.result;
        return {
          ...getConfigData(),
          rejectAPI: rejectAPI?.trim(),
          tokens: authenticationResult?.tokens,
        };
      default:
        break;
    }
  };

  /**
   * Método para realizar a requisição
   * @params api, key, name, index => endereço da api (NODE), chave para atualizar o resultado na store, nome para feedback (botão, notificação) e indice para atualização da view
   * @returns void
   */
  const makeTheRequest = async ({ api, key, name, method }, index) => {
    const apiResponse = await Api[method ?? 'post'](
      `/${api}`,
      getPayload(key)
    ).catch(({ response }) => response.data);

    if (!!apiResponse) {
      updateResult(key, { ...apiResponse });

      if (
        apiResponse?.error ||
        apiResponse?.errors ||
        apiResponse?.data?.errors ||
        apiResponse?.status >= 400
      ) {
        handleError(name, index);
      } else {
        handleSuccess(name, index);
      }
    }
  };

  const getScopes = ( consentIntent ) => {
    var scopes = new Set();
    consentIntent.permissions.forEach((permission) => {
        Object.keys(PermissionEnum).forEach((key) => {
          if (permission.startsWith(key)) {
            scopes.add(PermissionEnum[key]);
          }
        });
    });
    return scopes;
  }

  /**
   * Método para lidar com o evento de clique do botão de ação da etapa ativa
   * Inicia a conexão com o socket e ouve as mensagens de feedback
   * @params step, index => dados da etapa serão usados no request e o índice na atualização da view
   * @returns void
   */
  const onStepActionClick = async (step, index) => {
    if (configDataIsOk()) {
      resetStepResult(index);

      updateStepActionButtonStatus(index, {
        loader: true,
        status: 'initial',
        text: `REALIZA: ${step.name}`,
      });

      socket = io();
      socket.on('feedback', (msg) =>
        updateStepActionButtonStatus(index, { text: `STATUS: ${msg}` })
      );

      try {
        await makeTheRequest(step, index);
      } catch (error) {
        handleError(step.name, index);
      }
    } else {
      notifications.danger(
        'Todos os campos da configuração são obrigatórios!',
        3000
      );
    }
  };

  /**
   * Métodos para lidar com as alterações das etapas
   * @params id => identificador para ativar a etapa clicada
   * @returns void
   */
  const activeStepChange = (id) =>
    (steps = steps.map((step) => ({ ...step, active: step.id === id })));
  const prevStepClick = (id) => activeStepChange(id - 1);
  const nextStepClick = (id) => activeStepChange(id + 1);

  /**
   * Método reativo para verificar se deve desabilitar o botão de ação
   * @params key => chave para comparação
   * @returns boolean
   */
  $: shouldDisableTheButton = (key) => {
    const consentIntent = getResult('service')?.consentIntent;
    const authentication = getResult('service')?.authentication;

    switch (key) {
      case 'authentication':
        return !consentIntent?.data?.consentId;
      case 'makePayment':
        return !authentication?.result?.tokens;
      default:
        return false;
    }
  };
</script>

<form class="form-payment" bind:clientWidth={stepWidth}>
  <Stepper
    {steps}
    {stepWidth}
    on:step-click={({ detail }) => activeStepChange(detail)}
  >
    {#each steps as step, i}
      <div class="step" style="width: {stepWidth}px" class:active={step.active}>
        <svelte:component this={stepFormComponents[step.component]}>
          <div class="buttons" slot="buttons">
            <Button
              disabled={i === 0}
              class="step-button -prev"
              dataCy="prev-button-step-{i + 1}"
              id="prevStep{i + 1}Button"
              on:buttonClick={() => prevStepClick(step.id)}
            >
              <span class="material-icons icon">west</span>
              <span class="text">Voltar</span>
            </Button>

            <Button
              disabled={shouldDisableTheButton(step.key)}
              loader={step.button.loader}
              class={`step-action-button feedback-${step.button.status}`}
              dataCy="action-button-step-{i + 1}"
              id="actionButtonStep{i + 1}"
              on:buttonClick={() => onStepActionClick(step, i)}
            >
              {#if step.button.icon}
                <span class="material-icons icon">{step.button.icon}</span>
              {/if}
              {step.button.text}
            </Button>

            <Button
              disabled={steps.length === i + 1}
              class="step-button -next"
              dataCy="next-button-step-{i + 1}"
              id="nextStep{i + 1}Button"
              on:buttonClick={() => nextStepClick(step.id)}
            >
              <span class="text">Avançar</span>
              <span class="material-icons icon">east</span>
            </Button>
          </div>
        </svelte:component>
      </div>
    {/each}
  </Stepper>
</form>
<Section title="Consultar Consentimento" isOpen={false} id={'service'}>
  <ConsultServiceForm />
</Section>

<style>
  .form-payment {
    grid-template-columns: 1fr;
  }

  .form-payment :global(.result) {
    margin-top: 2rem;
    overflow: auto;
  }
  .form-payment :global(.logs),
  .form-payment :global(.result h3) {
    margin: 0;
  }

  .form-payment .buttons {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 2rem;
    grid-template-areas: 'prevStepButton stepActionButton nextStepButton';
    margin-top: 1rem;
    width: 100%;
  }
  .form-payment .buttons :global(button) {
    grid-column: initial;
    justify-self: center;
  }
  .form-payment .buttons :global(.step-action-button) {
    grid-area: stepActionButton;
  }
  @media (max-width: 600px) {
    .form-payment .buttons :global(.step-action-button) {
      width: 100%;
    }
  }
  .form-payment .buttons :global(.step-button) {
    padding: 1rem;
    background: var(--bg-content-color);
    color: var(--primary-color);
  }
  .form-payment .buttons :global(.step-button:disabled) {
    opacity: 0;
  }
  .form-payment .buttons :global(.step-button > .icon) {
    margin: 0 0.5rem;
    color: var(--primary-color);
  }
</style>
