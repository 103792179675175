<script>
  import { store, results } from '../../../stores/store.js';

  // Components
  import Input from '../../fields/Input.svelte';
  import Textarea from '../../fields/Textarea.svelte';
  import Logs from '../../Logs.svelte';
  import Notice from '../../Notice.svelte';
</script>

{#if $results?.payment?.authentication?.result?.tokens}
  <Notice text="Você está autenticado!" dataCy="payment-message" />
{:else}
  <Notice
    type="warning"
    text="Para realizar o pagamento você precisa se autenticar antes."
    dataCy="payment-message"
  />
{/if}
<Input
  text="Pagamento API"
  key="paymentsAPIField"
  bind:value={$store.business.payment.paymentsAPI}
/>
<Textarea
  text="Pagamento (JSON)"
  key="paymentPayload"
  bind:value={$store.business.payment.paymentPayload}
/>
<slot name="buttons" />
{#if $results?.payment?.makePayment}
  <div class="result">
    <h3>Resultado do pagamento:</h3>
    <Logs logs={$results?.payment?.makePayment} />
  </div>
{/if}
