<script>
  import { store, defaultData } from '../../stores/store.js';
  import { notifications } from '../../stores/notifications.js';

  import Checkbox from '../fields/Checkbox.svelte';
  import Textarea from '../fields/Textarea.svelte';
  import Input from '../fields/Input.svelte';
  import ConfigHandlerForm from './ConfigHandlerForm.svelte';
  import { checkForValidJson } from '../../stores/verify-json.js';

  const removeCurrentConfiguration = () => store.update((store) => defaultData);

  const addConfigurationByJsonFile = (event) => {
    const uploadedFile = event?.target?.files[0];

    if (uploadedFile?.type !== 'application/json') {
      notifications.danger('Apenas arquivos no formato JSON', 2000);
      return;
    }

    if (uploadedFile) {
      try {
        const fileReader = new FileReader();
        fileReader.onload = (e) =>
          store.update((store) => JSON.parse(e.target.result));
        fileReader.readAsText(uploadedFile);
        const configFileField = document.getElementById('configFileField');
        configFileField.value = '';
        checkForValidJson();
        notifications.success('Configuração definida com sucesso!', 2000);
      } catch (error) {
        notifications.danger('Erro no carregamento do arquivo JSON', 2000);
      }
    } else {
      notifications.danger('Erro no carregamento do arquivo JSON', 2000);
    }
  };
</script>

<form class="form-config">
  <div class="config-store-import">
    <ConfigHandlerForm />
  </div>

  <div class="config-form-wrapper">
    <div class="text">
      <span>ou</span>
    </div>

    <Checkbox
      text="Verifica SSL?"
      bind:checked={$store.config.global.checkSSL}
    />

    <h2 class="title">Segurança</h2>

    <Textarea
      text="BRSEAL KEY"
      key="clientSignatureKeyFile"
      bind:value={$store.config.security.clientSignatureKey}
    />
    <Textarea
      text="BRCAC KEY"
      key="clientTransportKeyFile"
      bind:value={$store.config.security.clientTransportKey}
    />
    <Textarea
      text="BRCAC CERT"
      key="clientTransportCertFile"
      bind:value={$store.config.security.clientTransportCert}
    />
    <Textarea
      text="CA ROOT"
      key="caRootCertificateFile"
      bind:value={$store.config.security.caRootCertificate}
    />

    <Textarea
      text="ID TOKEN KEY (Perfil Único)"
      key="idTokenEncKey"
      bind:value={$store.config.security.idTokenEncKey}
    />

    <br/>

    <Input
      text="kid"
      key="kidField"
      bind:value={$store.config.security.clientSignatureKid}
    />
    <Input
      text="id_token kid (Perfil Único)"
      key="idTokenKidField"
      bind:value={$store.config.security.idTokenEncKid}
    />

    <h2 class="title">Authorization Server</h2>

    <Input
      text="Endereço (.well-known)"
      key="authorizationServerURLField"
      bind:value={$store.config.as.authorizationServerURL}
    />

    <Input
      text="Redirect URI"
      key="redirectURIField"
      bind:value={$store.config.as.redirectURI}
    />

    <h2 class="title">Organization</h2>

    <Input
      text="ID (ISS)"
      key="organizationIDField"
      bind:value={$store.config.organization.id}
    />

    <Input
      text="CNPJ"
      key="organizationCNPJField"
      bind:value={$store.config.organization.cnpj}
    />

    <h2 class="title">Client</h2>

    <Input text="ID" key="clientIDField" bind:value={$store.config.client.id} />
  </div>
</form>

<style>
  .config-store-import {
    display: flex;
    gap: 2rem;
    grid-column: 1/3;
  }
  .config-form-wrapper {
    grid-column: 1/3;
    display: grid;
    gap: 1rem;
    column-gap: 2rem;
    grid-template-columns: 1fr minmax(25rem, 1fr);
  }
  @media (max-width: 1024px) {
    .config-form-wrapper {
      display: block;
    }
  }
  .config-form-wrapper .text {
    grid-column: 1/3;
    text-align: center;
    position: relative;
  }
  .config-form-wrapper .text span {
    background: var(--bg-page-color);
    position: relative;
    padding: 0 0.5rem;
  }
  .config-form-wrapper .text::before {
    content: '';
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -0.5px;
    border-bottom: 1px dashed var(--border-field-color);
  }
</style>
