<script>
  import { getContext } from 'svelte';
  import { store, defaultData } from '../../../stores/store.js';
  import { notifications } from '../../../stores/notifications.js';

  import Input from '../../fields/Input.svelte';
  import { checkForValidJson } from '../../../stores/verify-json.js';

  const { close } = getContext('simple-modal');

  const addConfigurationByJsonFile = (event) => {
    const uploadedFile = event?.target?.files[0];

    if (uploadedFile?.type !== 'application/json') {
      notifications.danger('Apenas arquivos no formato JSON', 2000);
      return;
    }

    if (uploadedFile) {
      try {
        const fileReader = new FileReader();
        fileReader.onload = (e) => {
          store.update((store) => JSON.parse(e.target.result));
          checkForValidJson();
        };
        fileReader.readAsText(uploadedFile);
        const configFileField = document.getElementById('configFileField');
        configFileField.value = '';
        notifications.success('Configuração definida com sucesso!', 2000);
        close();
      } catch (error) {
        notifications.danger('Erro no carregamento do arquivo JSON', 2000);
      }
    } else {
      notifications.danger('Erro no carregamento do arquivo JSON', 2000);
    }
  };
</script>

<div class="config-file-wrapper config-store-section">
  <Input
    text="Carregue um arquivo (JSON) de configuração:"
    type="file"
    class="config-file-field"
    key="configFileField"
    on:change={addConfigurationByJsonFile}
  />
  <div class="actions">
    <a
      class="download-link"
      href="assets/quickSimulatorDataExample.json"
      download>Clique para baixar arquivo de exemplo</a
    >
  </div>
</div>

<style>
  .config-store-section {
    flex: 1;
  }
  .config-file-wrapper {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .config-file-wrapper .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  @media (max-width: 465px) {
    .config-file-wrapper .actions {
      flex-direction: column;
    }
  }
  .config-file-wrapper .actions > * {
    font-size: 0.8rem;
    color: var(----cyan-color);
    display: flex;
    align-items: center;
  }
</style>
