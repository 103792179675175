import { get } from 'svelte/store';
import { store, defaultData } from './store.js';

export const checkForValidJson = () => {
  const currentStore = get(store);
  let newStore = { config: {}, business: {} };

  newStore.config = { ...defaultData.config, ...currentStore.config };
  if (currentStore.business.hasOwnProperty('dataSharing') || currentStore.business.hasOwnProperty('service')) {
    return;
  }

  newStore.business.dataSharing = defaultData.business.dataSharing;
  newStore.business.dataSharing = currentStore.business.hasOwnProperty(
    'dataSharing'
  )
    ? {
        ...defaultData.business.dataSharing,
        ...currentStore.business.dataSharing,
      }
    : (newStore.business.dataSharing = defaultData.business.dataSharing);
  
  newStore.business.service = defaultData.business.service;
  newStore.business.service = currentStore.business.hasOwnProperty(
    'service'
  )
    ? {
        ...defaultData.business.service,
        ...currentStore.business.service,
      }
    : (newStore.business.service = defaultData.business.service);

  newStore.business.payment = {
    ...defaultData.business.payment,
    ...currentStore.business.payment,
  };

  if (
    !currentStore.business.hasOwnProperty('dataSharing') && !currentStore.business.hasOwnProperty('service') &&
    currentStore.business.request
  ) {
    newStore.business.payment.request = currentStore.business.request;
  }

  if (newStore != currentStore) {
    newStore.business.consentType = 'PAYMENT';
    store.update(() => newStore);
  }
};
