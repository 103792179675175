<script>
  import { store, results, getStore } from '../../stores/store.js';
  import { notifications } from '../../stores/notifications.js';

  import Input from '../fields/Input.svelte';
  import Button from '../Button.svelte';
  import Logs from '../Logs.svelte';

  import {
    configDataIsOk,
    getConfigData,
  } from '../../utils/handleConfigData.js';

  import Api from '../../services/Api.js';

  let loader = false;

  const updateResult = (data) => {
    results.update((results) => {
      return {
        ...results,
        payment: {
          ...results.payment,
          getPayment: data,
        },
      };
    });
  };

  const handleClick = async () => {
    if (configDataIsOk()) {
      loader = true;

      try {
        const request = getStore('business').payment?.request;
        const payload = {
          ...getConfigData(),
          url: request?.url?.trim(),
        };

        const apiResponse = await Api.post('/consult', payload).catch(
          ({ response }) => response.data
        );

        updateResult({ ...apiResponse });
      } catch (error) {
        updateResult({ ...error });
        notifications.danger(`Houve um erro na requisição`, 2000);
      } finally {
        loader = false;
      }
    } else {
      notifications.danger(
        'Todos os campos da configuração são obrigatórios!',
        3000
      );
    }
  };
</script>

<form class="form-get-payment">
  <div class="input-wrapper">
    <Input
      text="Url completa do GET (consentimento ou pagamento)"
      class="url-field"
      dataCy="consult-field"
      key="consultAPIField"
      bind:value={$store.business.payment.request.url}
    />

    <Button on:buttonClick={handleClick} {loader} dataCy="consult-button">
      {loader ? 'Enviando' : 'Enviar'}
    </Button>
  </div>

  {#if $results?.payment?.getPayment}
    <div class="result">
      <h3>Resultado</h3>
      <Logs logs={$results?.payment?.getPayment} />
    </div>
  {/if}
</form>

<style>
  .form-get-payment :global(.url-field) {
    flex: 1;
  }

  .form-get-payment .result {
    margin-top: 1rem;
    grid-column: 1/3;
  }

  .form-get-payment :global(button) {
    min-width: initial;
    margin-top: 1.6rem;
  }
  @media (max-width: 600px) {
    .form-get-payment :global(button) {
      margin-top: 0;
      width: 100%;
    }
  }

  .form-get-payment :global(.logs),
  .form-get-payment h3 {
    margin: 0;
  }

  .form-get-payment .input-wrapper {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 5fr 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 600px) {
    .form-get-payment .input-wrapper {
      flex-direction: column;
      gap: 0;
    }
  }
</style>
