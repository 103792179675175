<script>
  import { Router, Route } from 'svelte-navigator';
  import Modal from 'svelte-simple-modal';

  import RouteTransition from './components/RouteTransition.svelte';
  import Toast from './components/Toast.svelte';

  import Home from './pages/Home.svelte';
  import Callback from './pages/Callback.svelte';

  import {checkForValidJson} from './stores/verify-json';

  checkForValidJson();
</script>

<Router>
  <Modal>
    <div class="page">
      <header class="heading">
        <h1>TPP Quick Simulator</h1>
      </header>
      <main class="content">
        <Route path="/">
          <RouteTransition direction="backward">
            <Home />
          </RouteTransition>
        </Route>
        <Route path="/cb">
          <RouteTransition>
            <Callback />
          </RouteTransition>
        </Route>
      </main>
      <footer>Opus Software - Quick Simulator - v.0.4.0</footer>
    </div>
    <Toast />
  </Modal>
</Router>

<style>

  .page {
		width: 100%;
		min-height: 100vh;
    display: flex;
    flex-direction: column;
	}

	.page .heading {
		display: flex;
    align-items: center;
    height: 100px;
		flex-shrink: 0;
	}
	

	.page .heading h1 {
		flex: 1;
	}

	.page .content {
    width: 100%;
    max-width: 1024px;
    margin: 0 auto;
		position: relative;
    flex: 1;
	}

  footer {
    margin: 10px auto;
    font-size: .8rem;
  }
</style>
