export const dataSharingUrlsV1 = [
  {
    type: 'customers',
    name: 'Customers',
    basePath: '/open-insurance/customers',
    urls: [
      {
        method: 'GET',
        path: '/v1/business/identifications',
        validateToken: true,
        scope: 'customers',
        validateJWS: false,
        consentPermission: 'CUSTOMERS_BUSINESS_IDENTIFICATIONS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/business/complimentary-information',
        validateToken: true,
        scope: 'customers',
        validateJWS: false,
        consentPermission: 'CUSTOMERS_BUSINESS_ADITTIONALINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/business/qualifications',
        validateToken: true,
        scope: 'customers',
        validateJWS: false,
        consentPermission: 'CUSTOMERS_BUSINESS_QUALIFICATION_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/personal/identifications',
        validateToken: true,
        scope: 'customers',
        validateJWS: false,
        consentPermission: 'CUSTOMERS_PERSONAL_IDENTIFICATIONS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/personal/complimentary-information',
        validateToken: true,
        scope: 'customers',
        validateJWS: false,
        consentPermission: 'CUSTOMERS_PERSONAL_ADITTIONALINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/personal/qualifications	',
        validateToken: true,
        scope: 'customers',
        validateJWS: false,
        consentPermission: 'CUSTOMERS_PERSONAL_QUALIFICATION_READ',
        validateConsentId: true,
        mtls: true,
      },
    ],
  },
  {
    type: 'insurance-acceptance-and-branches-abroad',
    name: 'Insurance acceptance and branches abroad',
    basePath: '/open-insurance/insurance-acceptance-and-branches-abroad',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-acceptance-and-branches-abroad',
        validateToken: true,
        scope: 'insurance-acceptance-and-branches-abroad',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_ACCEPTANCE_AND_BRANCHES_ABROAD_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-acceptance-and-branches-abroad/{ID}/policy-info',
        validateToken: true,
        scope: 'insurance-acceptance-and-branches-abroad',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_ACCEPTANCE_AND_BRANCHES_ABROAD_POLICYINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-acceptance-and-branches-abroad/{ID}/premium',
        validateToken: true,
        scope: 'insurance-acceptance-and-branches-abroad',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_ACCEPTANCE_AND_BRANCHES_ABROAD_PREMIUM_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-acceptance-and-branches-abroad/{ID}/claim',
        validateToken: true,
        scope: 'insurance-acceptance-and-branches-abroad',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_ACCEPTANCE_AND_BRANCHES_ABROAD_CLAIM_READ',
        validateConsentId: true,
        mtls: true,
      },
    ]
  },
  {
    type: 'insurance-auto',
    name: 'Insurance auto',
    basePath: '/open-insurance/insurance-auto',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-auto',
        validateToken: true,
        scope: 'insurance-auto',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_AUTO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-auto/{ID}/policy-info',
        validateToken: true,
        scope: 'insurance-auto',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_AUTO_POLICYINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-auto/{ID}/premium',
        validateToken: true,
        scope: 'insurance-auto',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_AUTO_PREMIUM_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-auto/{ID}/claim',
        validateToken: true,
        scope: 'insurance-auto',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_AUTO_CLAIM_READ',
        validateConsentId: true,
        mtls: true,
      },
    ]
  },
  {
    type: 'insurance-financial-risk',
    name: 'Insurance financial risk',
    basePath: '/open-insurance/insurance-financial-risk',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-financial-risk',
        validateToken: true,
        scope: 'insurance-financial-risk',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_FINANCIAL_RISKS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-financial-risk/{ID}/policy-info',
        validateToken: true,
        scope: 'insurance-financial-risk',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_FINANCIAL_RISKS_POLICYINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-financial-risk/{ID}/premium',
        validateToken: true,
        scope: 'insurance-financial-risk',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_FINANCIAL_RISKS_PREMIUM_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-financial-risk/{ID}/claim',
        validateToken: true,
        scope: 'insurance-financial-risk',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_FINANCIAL_RISKS_CLAIM_READ',
        validateConsentId: true,
        mtls: true,
      },
    ]
  },
  {
    type: 'insurance-housing',
    name: 'Insurance housing',
    basePath: '/open-insurance/insurance-housing',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-housing',
        validateToken: true,
        scope: 'insurance-housing',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_HOUSING_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-housing/{ID}/policy-info',
        validateToken: true,
        scope: 'insurance-housing',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_HOUSING_POLICYINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-housing/{ID}/premium',
        validateToken: true,
        scope: 'insurance-housing',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_HOUSING_PREMIUM_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-housing/{ID}/claim',
        validateToken: true,
        scope: 'insurance-housing',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_HOUSING_CLAIM_READ',
        validateConsentId: true,
        mtls: true,
      },
    ]
  },
  {
    type: 'insurance-patrimonial',
    name: 'Insurance patrimonial',
    basePath: '/open-insurance/insurance-patrimonial',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-patrimonial',
        validateToken: true,
        scope: 'insurance-patrimonial',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_PATRIMONIAL_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-patrimonial/{ID}/policy-info',
        validateToken: true,
        scope: 'insurance-patrimonial',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_PATRIMONIAL_POLICYINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-patrimonial/{ID}/premium',
        validateToken: true,
        scope: 'insurance-patrimonial',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_PATRIMONIAL_PREMIUM_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-patrimonial/{ID}/claim',
        validateToken: true,
        scope: 'insurance-patrimonial',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_PATRIMONIAL_CLAIM_READ',
        validateConsentId: true,
        mtls: true,
      },
    ]
  },
  {
    type: 'insurance-responsibility',
    name: 'Insurance responsibility',
    basePath: '/open-insurance/insurance-responsibility',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-responsibility',
        validateToken: true,
        scope: 'insurance-responsibility',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_RESPONSIBILITY_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-responsibility/{ID}/policy-info',
        validateToken: true,
        scope: 'insurance-responsibility',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_RESPONSIBILITY_POLICYINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-responsibility/{ID}/premium',
        validateToken: true,
        scope: 'insurance-responsibility',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_RESPONSIBILITY_PREMIUM_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-responsibility/{ID}/claim',
        validateToken: true,
        scope: 'insurance-responsibility',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_RESPONSIBILITY_CLAIM_READ',
        validateConsentId: true,
        mtls: true,
      },
    ]
  },
  {
    type: 'insurance-rural',
    name: 'Insurance rural',
    basePath: '/open-insurance/insurance-rural',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-rural',
        validateToken: true,
        scope: 'insurance-rural',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_RURAL_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-rural/{ID}/policy-info',
        validateToken: true,
        scope: 'insurance-rural',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_RURAL_POLICYINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-rural/{ID}/premium',
        validateToken: true,
        scope: 'insurance-rural',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_RURAL_PREMIUM_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-rural/{ID}/claim',
        validateToken: true,
        scope: 'insurance-rural',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_RURAL_CLAIM_READ',
        validateConsentId: true,
        mtls: true,
      },
    ]
  },
  {
    type: 'insurance-transport',
    name: 'Insurance transport',
    basePath: '/open-insurance/insurance-transport',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-transport',
        validateToken: true,
        scope: 'insurance-transport',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_TRANSPORT_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-transport/{ID}/policy-info',
        validateToken: true,
        scope: 'insurance-transport',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_TRANSPORT_POLICYINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-transport/{ID}/premium',
        validateToken: true,
        scope: 'insurance-transport',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_TRANSPORT_PREMIUM_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-transport/{ID}/claim',
        validateToken: true,
        scope: 'insurance-transport',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_TRANSPORT_CLAIM_READ',
        validateConsentId: true,
        mtls: true,
      }
    ],
  },
  {
    type: 'insurance-life-pension',
    name: 'Insurance life pension',
    basePath: '/open-insurance/insurance-life-pension',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-life-pension/contracts',
        validateToken: true,
        scope: 'insurance-life-pension',
        validateJWS: false,
        consentPermission: 'LIFE_PENSION_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-life-pension/{ID}/contract-info',
        validateToken: true,
        scope: 'insurance-life-pension',
        validateJWS: false,
        consentPermission: 'LIFE_PENSION_CONTRACTINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-life-pension/{ID}/movements',
        validateToken: true,
        scope: 'insurance-life-pension',
        validateJWS: false,
        consentPermission: 'LIFE_PENSION_MOVEMENTS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-life-pension/{ID}/portabilities',
        validateToken: true,
        scope: 'insurance-life-pension',
        validateJWS: false,
        consentPermission: 'LIFE_PENSION_PORTABILITIES_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-life-pension/{ID}/withdrawals',
        validateToken: true,
        scope: 'insurance-life-pension',
        validateJWS: false,
        consentPermission: 'LIFE_PENSION_WITHDRAWALS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-life-pension/{ID}/claim',
        validateToken: true,
        scope: 'insurance-life-pension',
        validateJWS: false,
        consentPermission: 'LIFE_PENSION_CLAIM',
        validateConsentId: true,
        mtls: true,
      }
    ]
  },
  {
    type: 'insurance-pension-plan',
    name: 'Insurance pension plan',
    basePath: '/open-insurance/insurance-pension-plan',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-pension-plan/contracts',
        validateToken: true,
        scope: 'insurance-pension-plan',
        validateJWS: false,
        consentPermission: 'PENSION_PLAN_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-pension-plan/{ID}/contract-info',
        validateToken: true,
        scope: 'insurance-pension-plan',
        validateJWS: false,
        consentPermission: 'PENSION_PLAN_CONTRACTINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-pension-plan/{ID}/movements',
        validateToken: true,
        scope: 'insurance-pension-plan',
        validateJWS: false,
        consentPermission: 'PENSION_PLAN_MOVEMENTS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-pension-plan/{ID}/portabilities',
        validateToken: true,
        scope: 'insurance-pension-plan',
        validateJWS: false,
        consentPermission: 'PENSION_PLAN_PORTABILITIES_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-pension-plan/{ID}/withdrawals',
        validateToken: true,
        scope: 'insurance-pension-plan',
        validateJWS: false,
        consentPermission: 'PENSION_PLAN_WITHDRAWALS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-pension-plan/{ID}/claim',
        validateToken: true,
        scope: 'insurance-pension-plan',
        validateJWS: false,
        consentPermission: 'PENSION_PLAN_CLAIM',
        validateConsentId: true,
        mtls: true,
      }
    ]
  },
  {
    type: 'insurance-person',
    name: 'Insurance person',
    basePath: '/open-insurance/insurance-person',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-person',
        validateToken: true,
        scope: 'insurance-person',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_PERSON_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-person/{ID}/policy-info',
        validateToken: true,
        scope: 'insurance-person',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_PERSON_POLICYINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-person/{ID}/premium',
        validateToken: true,
        scope: 'insurance-person',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_PERSON_PREMIUM_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-person/{ID}/claim',
        validateToken: true,
        scope: 'insurance-person',
        validateJWS: false,
        consentPermission: 'DAMAGES_AND_PEOPLE_PERSON_CLAIM_READ',
        validateConsentId: true,
        mtls: true,
      },
    ]
  },
  {
    type: 'insurance-capitalization-title',
    name: 'Insurance capitalization title',
    basePath: '/open-insurance/insurance-capitalization-title',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-capitalization-title/plans',
        validateToken: true,
        scope: 'capitalization-title',
        validateJWS: false,
        consentPermission: 'CAPITALIZATION_TITLE_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-capitalization-title/{ID}/plan-info',
        validateToken: true,
        scope: 'capitalization-title',
        validateJWS: false,
        consentPermission: 'CAPITALIZATION_TITLE_PLANINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-capitalization-title/{ID}/events',
        validateToken: true,
        scope: 'capitalization-title',
        validateJWS: false,
        consentPermission: 'CAPITALIZATION_TITLE_EVENTS_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-capitalization-title/{ID}/settlements',
        validateToken: true,
        scope: 'capitalization-title',
        validateJWS: false,
        consentPermission: 'CAPITALIZATION_TITLE_SETTLEMENTS_READ',
        validateConsentId: true,
        mtls: true,
      }
    ]
  },
  {
    type: 'insurance-financial-assistance',
    name: 'Insurance financial assistance',
    basePath: '/open-insurance/insurance-financial-assistance',
    urls: [
      {
        method: 'GET',
        path: '/v1/insurance-financial-assistance/contracts',
        validateToken: true,
        scope: 'insurance-financial-assistance',
        validateJWS: false,
        consentPermission: 'FINANCIAL_ASSISTANCE_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-financial-assistance/{ID}/contract-info',
        validateToken: true,
        scope: 'insurance-financial-assistance',
        validateJWS: false,
        consentPermission: 'FINANCIAL_ASSISTANCE_CONTRACTINFO_READ',
        validateConsentId: true,
        mtls: true,
      },
      {
        method: 'GET',
        path: '/v1/insurance-financial-assistance/{ID}/movements',
        validateToken: true,
        scope: 'insurance-financial-assistance',
        validateJWS: false,
        consentPermission: 'FINANCIAL_ASSISTANCE_MOVEMENTS_READ',
        validateConsentId: true,
        mtls: true,
      }
    ]
  }
];

export const dataSharingUrlsV2 = [
  {
    type: 'resources',
    name: 'Resources',
    basePath: '/open-insurance/resources',
    urls: [
      {
        method: 'GET',
        path: '/v2/resources',
        validateToken: true,
        scope: 'resources',
        validateJWS: false,
        consentPermission: 'RESOURCES_READ',
        validateConsentId: true,
        mtls: true,
      },
    ],
  },
];
