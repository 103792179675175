<script>
  /** @type {"success"|"warning"|"info"|"error"} */
  export let type = 'info'
  export let text;
  export let showNotice = true;
</script>

{#if showNotice}
  <div class="notice -{type}" data-cy={$$props.dataCy}>
    <span class="material-icons icon">{type === 'success' ? 'check' : type}</span>
    <span class="text">{text}</span>
    <button on:click|preventDefault={() => showNotice = false}>x</button>
  </div>
{/if}

<style>
  .notice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    background: rgb(0, 191, 255, .3);
    color: darkblue;
    border-radius: 1rem;
  }
  @media (max-width: 600px)  {
    .notice { 
      font-size: .8rem;
    }
  }

  .notice.-success {
    background: lightgreen;
    color: darkgreen;
  }

  .notice.-warning {
    background: bisque;
    color: brown;
  }

  .notice.-error {
    background: indianred;
    color: white;
  }

  .notice .icon {
    margin-right: 1rem;
  }

  .notice button {
    height: auto;
  }

</style>
