<script>
  export let checked, text;
</script>

<label>
  <input type=checkbox bind:checked class="checkbox-field" />
  {text}
</label>

<style>
  .checkbox-field {
		width: initial;
	}
</style>