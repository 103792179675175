<script>
  import { onMount } from 'svelte';
  import { store, results, getStore } from '../../../stores/store.js';

  // Components
  import Input from '../../fields/Input.svelte';
  import Textarea from '../../fields/Textarea.svelte';
  import Logs from '../../Logs.svelte';

  import { ConsentType } from '../../../utils/consent-type.js';

  let typeKey = 'dataSharing';

  onMount(() => {
    const consentType = getStore('business').consentType;
    if (consentType === ConsentType.PAYMENT) {
      typeKey = 'payment';
    } else if (consentType === ConsentType.SERVICE) {
      typeKey = 'service';
    } else if (consentType === ConsentType.DATA_SHARING) {
      typeKey = 'dataSharing';
    }
  });
</script>

<Input
  text="Consentimento API"
  key="consentsAPIField"
  bind:value={$store.business[typeKey].consentsAPI}
/>
<Textarea
  text="Consentimento payload (JSON)"
  key="consentPayload"
  bind:value={$store.business[typeKey].consentPayload}
/>
<slot name="buttons" />
{#if $results[typeKey]?.consentIntent}
  <div class="result">
    <h3>Resultado da intenção de consentimento:</h3>
    <Logs logs={$results[typeKey]?.consentIntent} />
  </div>
{/if}
