import { getStore } from '../stores/store.js'

const getConfigData = () => {
  const { as, global, security, client, organization } = getStore('config')
  return {
    ...as,
    ...global,
    ...security,
    clientID: client.id,
    organizationID: organization.id,
  }
}

const configDataIsOk = () => {
  const configData = getConfigData()
  delete configData.checkSSL
  delete configData.idTokenEncKid;
  delete configData.idTokenEncKey;
  return Object.keys(configData).every(
    (key) => !!configData[key] && configData[key].trim() && configData[key].length > 0
  )
}

export { getConfigData, configDataIsOk }
