<script>
  import { sections } from '../stores/store.js';
	import { slide } from "svelte/transition";
	export let title, id;
	export let isOpen = false
	const toggle = () => {
    isOpen = !isOpen;
  };
</script>

<section class="form-section" transition:slide={{ duration: 300 }} id={'section-'+id}>
  <button on:click|preventDefault={toggle} aria-expanded={isOpen} class='bt-accordeon'>
    <h3>{title}</h3> 
    <svg class="arrow" class:isOpen={isOpen} width="20" height="20" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M9 5l7 7-7 7"></path></svg>
  </button>
  {#if isOpen}
    <slot></slot>
  {/if}
</section>

<style>

  .form-section { 
    padding: 2rem 0; 
    border-bottom: 1px dashed var(--border-field-color); 
  }

	.form-section h3 { 
    color: var(--primary-color); 
  }

  @media (max-width: 600px)  {
    .form-section h3 { 
      font-size: 1.4rem;
    }
  }
  
  .arrow {
    transform: rotate(-90deg);
  }
  
  .arrow.isOpen {
    transform: rotate(90deg);
  }
  
  .bt-accordeon {
		color: var(--primary-color);
		width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-size: 1.5rem;
		height: 30px;
	}
</style>