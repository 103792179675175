<script>
  import { getContext } from 'svelte';
  import {
    serviceUrlsV1
  } from '../../../utils/service-urls.js';
  import { store } from '../../../stores/store.js';
  import Input from '../../fields/Input.svelte';
  import Select from '../../fields/Select.svelte';

  const { close } = getContext('simple-modal');

  let selectedVersion = null;
  let selectedType = null;
  let selectedUrl = '';

  let basePath = '';

  $: selectedDetailsType = selectedType
    ? selectCurrentServiceUrl().find((url) => url.type === selectedType)
    : { urls: [] };

  $: path = basePath + selectedDetailsType.basePath + selectedUrl;

  const versions = [
    { key: 'v1', name: 'v1' }
  ];

  const save = () => {
    store.update((store) => ({
      ...store,
      business: {
        ...store.business,
        service: {
          ...store.business.service,
          serviceAPI: path,
        },
      },
    }));
    close();
  };

  const cancel = () => {
    close();
  };

  const selectCurrentServiceUrl = () => {
    let urlArray = [];
    switch (selectedVersion) {
      case 'v1':
        urlArray = serviceUrlsV1;
        break;
      default:
        urlArray = serviceUrlsV1;
        break;
    }

    return urlArray;
  };

  const mapToSelectOptions = (options, key, value) => {
    return options.map((option) => ({
      key: option[key],
      name: option[value],
    }));
  };

  const resetSelectedUrl = () => {
    selectedUrl = '';
  };

  const resetSelectedType = () => {
    selectedType = '';
    selectedUrl = '';
  };
</script>

<div class="popup-container">
  <div class="inputs-container">
    <Input key="base-path" text="Url Base" bind:value={basePath} />
  </div>
  <div class="selects-container">
    <Select
      key="version-type"
      label="Versão"
      bind:value={selectedVersion}
      arrayData={versions}
      on:change={resetSelectedType}
    />
    <Select
      key="url-type"
      label="Tipo de URL"
      bind:value={selectedType}
      disabled={!selectedVersion}
      arrayData={mapToSelectOptions(
        selectCurrentServiceUrl(),
        'type',
        'name'
      )}
      on:change={resetSelectedUrl}
    />
    <Select
      key="url-value"
      label="URL"
      bind:value={selectedUrl}
      disabled={!selectedType}
      arrayData={mapToSelectOptions(selectedDetailsType.urls, 'path', 'path')}
    />
  </div>
  <div class="preview-container">
    {#if basePath && selectedType && selectedUrl}
      <p class="path-preview">{path}</p>
    {/if}
  </div>
  <div class="buttons-container">
    <button on:click|preventDefault={cancel} class="btn btn-cancel"
      >Cancelar</button
    >
    <button
      on:click|preventDefault={save}
      disabled={!(selectedUrl && basePath)}
      class="btn btn-save">Salvar</button
    >
  </div>
</div>

<style>
  .popup-container {
    padding: 2rem;
    margin-top: 2rem;
  }
  .selects-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  .preview-container {
    height: 7vh;
  }
  .buttons-container {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }
  .btn {
    width: 100%;
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    justify-self: flex-end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
    cursor: pointer;
  }

  .btn-save {
    background: var(--primary-color);
    color: var(--white-color);
  }

  .btn-cancel {
    color: var(--primary-color);
  }

  button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .path-preview {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    font-style: italic;
    padding: 1rem;
    background: #efefef;
    border-radius: 0.5rem;
  }
</style>
