<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  export let value,
    key = '',
    label = '',
    disabled = false,
    arrayData = [];

  export function onChange(e) {
    dispatch('change', {
      value: e.target.value,
    });
  }
</script>

<div class="select-container">
  <label class="select-label">
    {label}
    <select
      name={key}
      bind:value
      class="select-form"
      on:change={onChange}
      {disabled}
    >
      <option value="" selected>Escolha aqui</option>
      {#each arrayData as optionData}
        <option value={optionData.key}>{optionData.name}</option>
      {/each}
    </select>
  </label>
</div>

<style>
  .select-container {
    display: flex;
    position: relative;
    width: 100%;
  }

  .select-form {
    border: thin solid var(--border-field-color);
    font-family: 'Roboto', sans-serif;
    padding: 1rem;
    border-radius: 0.5rem;
    width: 100%;
    margin-top: 0.5rem;
  }

  .select-label {
    width: 100%;
  }
</style>
