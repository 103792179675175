<script>
  import { getContext } from 'svelte';
  import { store, defaultData } from '../../../stores/store.js';
  import { notifications } from '../../../stores/notifications.js';

  import Textarea from '../../fields/Textarea.svelte';
  import { checkForValidJson } from '../../../stores/verify-json.js';

  const { close } = getContext('simple-modal');

  let configJson = '';

  const addConfigurationByJson = () => {
    if (configJson) {
      try {
        store.update(() => JSON.parse(configJson));
        checkForValidJson();
        notifications.success('Configuração definida com sucesso!', 2000);
        close();
      } catch (error) {
        notifications.danger('Erro no carregamento do JSON', 2000);
      }
    } else {
      notifications.danger('Erro no carregamento do JSON', 2000);
    }
  };
</script>

<div class="config-file-wrapper config-store-section">
  <Textarea text="JSON de configuração" key="jsonKey" bind:value={configJson} />
  <div class="actions">
    <button class="btn" on:click|preventDefault={addConfigurationByJson}
      >Salvar</button
    >
  </div>
</div>

<style>
  .config-store-section {
    flex: 1;
  }
  .config-file-wrapper {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .config-file-wrapper .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  @media (max-width: 465px) {
    .config-file-wrapper .actions {
      flex-direction: column;
    }
  }
  .config-file-wrapper .actions > * {
    display: flex;
    align-items: center;
  }

  .btn {
    background: var(--primary-color);
    color: var(--white-color);
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    width: 100%;
    justify-self: flex-end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
