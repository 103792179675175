<script>
  import Tabs from '../Tabs.svelte';
  import MakePaymentForm from './MakePaymentForm.svelte';
  import MakeDataSharingForm from './MakeDataSharingForm.svelte';
  import MakeServiceForm from './MakeServiceForm.svelte';
  import { ConsentType } from '../../utils/consent-type';

  let consentTypes = [
    {
      id: 1,
      key: ConsentType.DATA_SHARING,
      name: 'Dados Cadastrais e Seguros',
      component: MakeDataSharingForm,
    },
    {
      id: 2,
      key: ConsentType.SERVICE,
      name: 'Endosso e Sinistro',
      component: MakeServiceForm,
    }
    ];
</script>

<div class="phase-selector"><Tabs {consentTypes} /></div>

<style>
</style>
