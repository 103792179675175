<script>
  import {
    store,
    results,
    getStore,
    updateDataSharingResult,
  } from '../../stores/store.js';
  import { notifications } from '../../stores/notifications.js';

  import Input from '../fields/Input.svelte';
  import Button from '../Button.svelte';
  import Logs from '../Logs.svelte';

  import {
    configDataIsOk,
    getConfigData,
  } from '../../utils/handleConfigData.js';

  import Api from '../../services/Api.js';

  let loader = false;

  const updateResult = (data) => {
    updateDataSharingResult('getDataSharing', data);
  };

  const handleClick = async () => {
    if (configDataIsOk()) {
      loader = true;

      try {
        const request = getStore('business').dataSharing?.request;
        const payload = {
          ...getConfigData(),
          url: request?.url?.trim(),
        };

        const apiResponse = await Api.post(
          '/consult/data-sharing',
          payload
        ).catch(({ response }) => response.data);

        updateResult({ ...apiResponse });
      } catch (error) {
        updateResult({ ...error });
        notifications.danger(`Houve um erro na requisição`, 2000);
      } finally {
        loader = false;
      }
    } else {
      notifications.danger(
        'Todos os campos da configuração são obrigatórios!',
        3000
      );
    }
  };
</script>

<form class="form-get-dataSharing">
  <div class="input-wrapper">
    <Input
      text="Url completa do GET (consentimento)"
      class="url-field"
      dataCy="consult-field"
      key="consultAPIField"
      bind:value={$store.business.dataSharing.request.url}
    />

    <Button on:buttonClick={handleClick} {loader} dataCy="consult-button">
      {loader ? 'Enviando' : 'Enviar'}
    </Button>
  </div>

  {#if $results?.dataSharing?.getDataSharing}
    <div class="result">
      <h3>Resultado</h3>
      <Logs logs={$results?.dataSharing?.getDataSharing} />
    </div>
  {/if}
</form>

<style>
  .form-get-dataSharing :global(.url-field) {
    flex: 1;
  }

  .form-get-dataSharing .result {
    margin-top: 1rem;
    grid-column: 1/3;
  }

  .form-get-dataSharing :global(button) {
    min-width: initial;
    margin-top: 1.6rem;
  }
  @media (max-width: 600px) {
    .form-get-dataSharing :global(button) {
      margin-top: 0;
      width: 100%;
    }
  }

  .form-get-dataSharing :global(.logs),
  .form-get-dataSharing h3 {
    margin: 0;
  }

  .form-get-dataSharing .input-wrapper {
    grid-column: 1/3;
    display: grid;
    grid-template-columns: 5fr 1fr;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 600px) {
    .form-get-dataSharing .input-wrapper {
      flex-direction: column;
      gap: 0;
    }
  }
</style>
