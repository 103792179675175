<script>
  import { getContext } from 'svelte';
  import { store } from '../../../stores/store.js';
  import { notifications } from '../../../stores/notifications.js';

  const { close } = getContext('simple-modal');

  const copy = (text) => {
    navigator.clipboard.writeText(text);
    notifications.success('Configuração exportado com sucesso!', 2000);
    close();
  };
  const exportJsonFile = (exportObj) => {
    var dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', 'config.json');
    document.body.appendChild(downloadAnchorNode);

    downloadAnchorNode.click();
    downloadAnchorNode.remove();

    notifications.success('Configuração exportada com sucesso!', 2000);

    close();
  };
</script>

<div class="config-file-wrapper config-store-section">
  <div class="json-container">
    <pre>{JSON.stringify($store, null, 2)}</pre>
  </div>
  <div class="actions">
    <button class="btn" on:click|preventDefault={copy(JSON.stringify($store))}
      >Copiar</button
    >
    <button class="btn" on:click|preventDefault={exportJsonFile($store)}
      >Salvar arquivo</button
    >
  </div>
</div>

<style>
  .config-store-section {
    flex: 1;
  }
  .config-file-wrapper {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .config-file-wrapper .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    gap: 2rem;
  }
  @media (max-width: 465px) {
    .config-file-wrapper .actions {
      flex-direction: column;
    }
  }
  .config-file-wrapper .actions > * {
    display: flex;
    align-items: center;
  }

  .btn {
    background: var(--primary-color);
    color: var(--white-color);
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    width: 100%;
    justify-self: flex-end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .json-container {
    overflow: scroll;
    height: 65vh;
    padding: 1rem;
    background-color: #eeeeee;
  }
</style>
