<script>
  import { beforeUpdate, getContext } from 'svelte';

  import { store, results, getResult, defaultData } from '../../../stores/store.js';

  import { notifications } from '../../../stores/notifications.js';

  import Api from '../../../services/Api.js';

  // Components
  import Input from '../../fields/Input.svelte';
  import Logs from '../../Logs.svelte';
  import Notice from '../../Notice.svelte';
  import { getConfigData } from '../../../utils/handleConfigData.js';
  import PopupFinancialDataUrl from '../modal/PopupFinancialDataUrl.svelte';

  const { open } = getContext('simple-modal');

  const showUrlBuilderPopup = () => {
    open(PopupFinancialDataUrl);
  };

  const refreshToken = async () => {
    const { result } = getResult('dataSharing').authentication;
    const payload = {
      ...getConfigData(),
      refreshToken: result?.tokens?.refresh_token,
    };

    try {
      const apiResponse = await Api['post'](
        `/consent/refresh-token`,
        payload
      ).catch(({ response }) => response.data);

      if (!!apiResponse) {
        results.update((results) => {
          const updatedResult = {
            payment: results.payment,
            dataSharing: {
              ...results.dataSharing,
              authentication: {
                ...results.dataSharing.authentication,
                result: {
                  ...results.dataSharing.authentication.result,
                  tokens: apiResponse.tokens,
                },
              },
            },
          };

          return updatedResult;
        });
      }
    } catch (error) {
      notifications.danger(error, 3000);
    }
  };

  beforeUpdate(() => {
    if(!$store.business.dataSharing){
      $store.business.dataSharing = defaultData.business.dataSharing;
    }
  });
</script>

{#if $results?.dataSharing?.authentication?.result?.tokens}
  <Notice text={`Você está autenticado!`} dataCy="financial-data-message" />
  <div class="tokens-container">
    <Notice
      type="success"
      text={`Access Token:\n${$results?.dataSharing?.authentication?.result?.tokens?.access_token}`}
    />
    <Notice
      type="success"
      text={`Refresh Token:\n${$results?.dataSharing?.authentication?.result?.tokens?.refresh_token}`}
    />
  </div>
  <button on:click|preventDefault={refreshToken} class="btn-refresh"
    >Atualizar token</button
  >
{:else}
  <Notice
    type="warning"
    text="Para realizar uma consulta você precisa se autenticar antes."
    dataCy="financial-data-message"
  />
{/if}
<div class="input-container">
  <Input
    text="Consulta API"
    key="dataSharingAPIField"
    bind:value={$store.business.dataSharing.financialDataAPI}
  />
  <button on:click|preventDefault={showUrlBuilderPopup} class="btn"
    ><span class="material-icons icon">edit</span></button
  >
</div>
<slot name="buttons" />
{#if $results?.dataSharing?.financialData}
  <div class="result">
    <h3>Resultado:</h3>
    <Logs logs={$results?.dataSharing?.financialData} />
  </div>
{/if}

<style>
  .tokens-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 0.8rem;
  }

  .btn-refresh {
    color: var(--primary-color);
    padding: 1rem 3rem;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .input-container {
    display: grid;
    grid-template-columns: 1fr min-content;
    align-items: flex-end;
    gap: 1rem;
  }

  .btn {
    background: var(--primary-color);
    color: var(--white-color);
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    width: min-content;
    justify-self: flex-end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0;
  }
</style>
