<script>
  import { fly, fade } from 'svelte/transition';
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

  export let x = 50;
  export let duration = 250;
  export let delay = 500;
  /** @type {"forward"|"backward"} */
  export let direction = 'forward';
  /** @type { id, key, name, active, accessible } */
  export let steps = [];
  export let stepWidth = 0;

  $: directionFactor = direction === 'forward' ? 1 : -1;
  $: getActiveStepIndex = steps.findIndex((step) => step.active);
</script>

<div class="stepper-wrapper">
  <ul class="steps" style="width: {stepWidth}px">
    {#each steps as { id, name, active, accessible }}
      <li class:active>
        <button
          id="stepButton{id}"
          on:click|preventDefault={() => dispatch('step-click', id)}
        >
          <span class="dot">{id}</span>
          <span class="text">{name}<span /></span></button
        >
      </li>
    {/each}
  </ul>
  <div
    class="content"
    in:fly={{ x: x * directionFactor, duration, delay }}
    out:fly={{ x: -x * directionFactor, duration }}
    style="transform: translateX(-{stepWidth * getActiveStepIndex}px);"
  >
    <slot />
  </div>
</div>

<style>
  .stepper-wrapper {
    width: 100%;
    overflow: hidden;
  }
  .steps {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding: 0;
  }
  .steps li {
    position: relative;
    flex: 1;
  }
  .steps li:last-child {
    flex: inherit;
  }
  .steps li:after {
    content: '';
    width: 100%;
    height: 1px;
    background: grey;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    opacity: 0.2;
  }
  .steps li button {
    display: flex;
    align-items: center;
    background: var(--bg-page-color);
    position: relative;
    z-index: 2;
    width: min-content;
  }

  .steps li button .dot {
    width: 2rem;
    height: 2rem;
    background: var(--bg-content-color);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    border-radius: 5rem;
  }

  .steps li button .text {
    width: 60%;
  }

  @media (max-width: 600px) {
    .steps li button .text {
      display: none;
    }
  }

  .steps li.active button .dot {
    background: var(--cyan-color);
  }
  .steps li button:disabled {
    cursor: not-allowed;
  }
  .steps li button:disabled > * {
    opacity: 0.6;
  }
  .stepper-wrapper .content {
    white-space: pre-wrap;
    display: inline-flex;
    position: relative;
    scrollbar-width: none;
    transform: translateX(0);
    will-change: transform;
    transition: transform 0.3s ease-out;
  }

  .stepper-wrapper .content :global(.step) {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  .stepper-wrapper .content :global(.step.active) {
    opacity: 1;
    height: auto;
  }
</style>
