<script>
  export let value, title
  let fieldCopy;
  const copy = () => {
    fieldCopy.select()
    document.execCommand('copy')
  }
</script>

<div class="field-copy-wrapper {$$props.class}">
  <h3>{title}</h3>
  <div class="field-copy">
    <textarea bind:value bind:this={fieldCopy} />
    <button on:click|preventDefault={copy}>
      <span class="material-icons">content_copy</span>
    </button>
  </div>
</div>

<style>
  .field-copy-wrapper.full-field {
    grid-column: 1/3;
  }
  .field-copy-wrapper h3 {
    margin: 0;
    margin-bottom: -1rem;
  }
  .field-copy {
    width: 100%;
    padding: 0 1rem;
    background: lightgreen;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: black;
  }
  .field-copy textarea,
  .field-copy button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    min-width: initial;
    outline: none;
    color: var(--cyan-color);
  }
  .field-copy textarea {
    flex: 1;
    color: var(--yellow-color)
  }
</style>
