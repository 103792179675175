<script>
  import { getContext } from "svelte";
  import { store, defaultData } from "../../stores/store.js";
  import PopupExportConfig from "./modal/PopupExportConfig.svelte";
  import PopupImportFile from "./modal/PopupImportFile.svelte";
  import PopupImportJson from "./modal/PopupImportJson.svelte";
  const { open } = getContext("simple-modal");

  const removeCurrentConfiguration = () => store.update((store) => defaultData);

  const showImportFile = () => {
    open(PopupImportFile);
  };

  const showImportJson = () => {
    open(PopupImportJson);
  };

  const showExportConfig = () => {
    open(PopupExportConfig);
  };
</script>

<div class="buttons-container">
  <button class="btn" on:click|preventDefault={showImportFile}
    >Importar arquivo</button
  >
  <button class="btn" on:click|preventDefault={showImportJson}
    >Inserir JSON</button
  >
  <button class="btn" on:click|preventDefault={showExportConfig}
    >Exportar</button
  >
  <button class="btn" on:click|preventDefault={removeCurrentConfiguration}
    >Limpar</button
  >
</div>

<style>
  .buttons-container {
    display: flex;
    justify-content: space-around;
    gap: 5rem;
    margin-top: 2rem;
    width: 100%;
  }

  .btn {
    background: var(--primary-color);
    color: var(--white-color);
    padding: 1rem 3rem;
    border-radius: 0.5rem;
    width: 100%;
    justify-self: flex-end;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
