<script>
  export let value, text, key;
</script>

<label>
  {text}
  <textarea name="{key}" id="{key}" class="textarea-field" cols="30" rows="10" bind:value></textarea>
</label>

<style>
  .textarea-field {
		border: thin solid var(--border-field-color);
		font-family: 'Roboto', sans-serif;
		padding: 1rem;
		border-radius: .5rem;
		width: 100%;
    margin-top: .5rem;
    resize: vertical;
	}
</style>