<script>
  import { onMount } from 'svelte';
  import { store, results, getStore } from '../../../stores/store.js';

  // Components
  import Input from '../../fields/Input.svelte';
  import Logs from '../../Logs.svelte';

  import { ConsentType } from '../../../utils/consent-type.js';

  let typeKey = 'dataSharing';
  let typeReject = 'rejectDataSharing';

  onMount(() => {
    const consentType = getStore('business').consentType;
    if (consentType === ConsentType.SERVICE) {
      typeKey = 'service';
      typeReject = 'rejectService';
    } else if (consentType === ConsentType.DATA_SHARING) {
      typeKey = 'dataSharing';
      typeReject = 'rejectDataSharing';
    } 
  });
</script>

<Input
  text="Rejeitar o consentimento API"
  bind:value={$store.business[typeKey].rejectAPI}
  key="rejectAPIField"
/>
<slot name="buttons" />
{#if $results.hasOwnProperty(typeKey)}
  {#if $results[typeKey][typeReject]}
    <div class="result">
      <h3>Resultado da revogação do consentimento:</h3>
      <Logs logs={$results[typeKey][typeReject]} />
    </div>
  {/if}
{/if}
